<template>
  <div>
    <template v-if="!isMobile">
      <div class="box" v-if="languageSelect === 'zh'">
        <div class="ViewTitle">{{ title }}</div>
        <div class="contain gery">
          <el-form
            :label-position="labelPosition"
            label-width="110px"
            :model="form"
          >
            <el-form-item label="账户">
              <el-input
                v-model="form.account"
                placeholder="请输入邮箱/手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="验证码" class="codeBox">
              <el-input v-model="form.code" placeholder="请输入验证码"></el-input>
              <el-button
                type="text"
                :disabled="disabled"
                class="setCode"
                @click="getCode()"
                >{{ setCode }}</el-button
              >
            </el-form-item>
            <el-form-item label="密码">
              <el-input
                v-model="form.password"
                show-password
                placeholder="请输入密码"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码">
              <el-input
                v-model="form.requirePas"
                placeholder="请再次输入您的密码"
                show-password
              ></el-input>
            </el-form-item>
            <div class="submit" @click="submit()">确认</div>
          </el-form>
        </div>
      </div>
      <div class="box" v-if="languageSelect === 'en'">
        <div class="ViewTitle">{{ titleEn }}</div>
        <div class="contain gery">
          <el-form
            :label-position="labelPosition"
            label-width="180px"
            :model="form"
          >
            <el-form-item label="Account">
              <el-input
                v-model="form.account"
                placeholder="Please enter your email/phone number"
              ></el-input>
            </el-form-item>
            <el-form-item label="Verification code" class="codeBox">
              <el-input
                v-model="form.code"
                placeholder="Please enter the verification code"
              ></el-input>
              <el-button
                type="text"
                :disabled="disabled"
                class="setCode"
                @click="getCodeEn()"
                >{{ setCodeEn }}</el-button
              >
            </el-form-item>
            <el-form-item label="Password">
              <el-input
                v-model="form.password"
                show-password
                placeholder="Please enter password"
              ></el-input>
            </el-form-item>
            <el-form-item label="Confirm password">
              <el-input
                v-model="form.requirePas"
                placeholder="Please enter your password again"
                show-password
              ></el-input>
            </el-form-item>
            <div class="submit" @click="submitEn()">Confirm</div>
          </el-form>
        </div>
      </div>
    </template>
    <div v-else class="m-secure-page"> 
      <Header :title="($i18n.locale == 'en' ? titleEn : title)"></Header>
      <div class="m-secure-box">
        <van-field v-model="form.account" :label="$t('user.account')" :placeholder="$t('user.accountTip')" required />
        <van-field
          v-model="form.code"
          center
          clearable
          required
          :label="$t('user.code')"
          :placeholder="$t('user.codeTip')"
        >
          <template #button>
            <van-button :disabled="disabled" size="small" type="primary"  @click="($i18n.locale == 'en' ? getCodeEn() : getCode())">{{ $i18n.locale == 'en' ?  setCodeEn : setCode }}</van-button>
          </template>
        </van-field>
        <van-field v-model="form.password" type="password" :label="$t('user.password')" :placeholder="$t('user.passwordTip')" required />
        <van-field v-model="form.requirePas" type="password" :label="$t('user.requirePas')" :placeholder="$t('user.requirePasTip')" required />

        <div class="m-submit-btn" @click="($i18n.locale == 'en' ? submitEn() : submit())">{{ $t('common.confirm') }}</div>
      </div>
      <Bottom></Bottom>
    </div>
  </div>
</template>
<script>
import { phoneCode, emailCode, upPassword } from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";
import Bottom from "@/components/mobile/Bottom.vue";
import Header from "@/components/mobile/Header.vue";
import {  Field, Button } from 'vant';

export default {
  name: "ChangePhone",
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
    Bottom,
    Header
  },
  data() {
    return {
      title: "修改密码",
      titleEn: "Modify password",
      labelPosition: "right",
      form: {
        account: "",
        code: "",
        password: "",
        requirePas: "",
      },
      disabled: false,
      setCode: "发送验证码",
      setCodeEn: "Verification code",
      languageSelect: "",
      isMobile: false
    };
  },
  computed: {},
  created() {
    if (localStorage.getItem("language")) {
      this.languageSelect = localStorage.getItem("language");
    } else {
      this.languageSelect = "zh";
    }
    let url = window.location.host;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "油气知识",
        text: "洞悉油气行业变化，体验AI大模型，就来油气知识！",
      };
      shareUrl(shareData);
    }, 1000);
  },
  mounted(){
    this.isMobile = window.matchMedia("(max-width: 767px)").matches;
  },
  methods: {
    //获取验证码 并只验证手机号 是否正确
    getCode() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      const reg1 = /^\d{6,13}$/;
      if (!this.form.account) {
        this.$message.error("请输入账号");
        return false;
      }
      if (!reg1.test(this.form.account) && !reg.test(this.form.account)) {
        this.$message.error("请输入正确的账号");
        return false;
      }
      if (reg.test(this.form.account)) {
        let data = {
          email: this.form.account,
          event: "changepwd",
        };
        emailCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtn(); //验证码倒数60秒
        });
      } else {
        let data = {
          phone: this.form.account,
          event: "changepwd",
        };
        phoneCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtn(); //验证码倒数60秒
        });
      }
    },
    tackBtn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCode = "获取验证码";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCode = time + "秒后重试";
          time--;
        }
      }, 1000);
    },
    submit() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      const reg1 = /^\d{6,13}$/;
      if (!this.form.account) {
        this.$message.error("请输入账号");
        return false;
      }
      if (!reg1.test(this.form.account) && !reg.test(this.form.account)) {
        this.$message.error("请输入正确的账号");
        return false;
      }
      if (!this.form.code) {
        this.$message.error("请输入验证码");
        return false;
      }
      if (!this.form.password) {
        this.$message.error("请输入密码");
        return false;
      }
      if (!this.form.requirePas) {
        this.$message.error("请再次输入密码");
        return false;
      }
      if (this.form.password !== this.form.requirePas) {
        this.$message.error("两次密码不一致");
        return false;
      }
      let data = {
        username: this.form.account,
        code: this.form.code,
        password: this.form.password,
      };
      upPassword(data).then(() => {
        this.$message({
          message: "修改成功！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userId");
            localStorage.removeItem("typeInfo");
            localStorage.removeItem("userInfo");
            // localStorage.clear();
            this.$store.state.access_token = "";
            this.$store.state.user = {};
            //跳转页面或执行方法
            this.$router.push("/");
          },
        });
      });
      console.log(this.form);
    },
    getCodeEn() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      const reg1 = /^\d{6,13}$/;
      if (!this.form.account) {
        this.$message.error("Please enter your account number");
        return false;
      }
      if (!reg1.test(this.form.account) && !reg.test(this.form.account)) {
        this.$message.error("Please enter the correct account number");
        return false;
      }
      if (reg.test(this.form.account)) {
        let data = {
          email: this.form.account,
          event: "changepwd",
        };
        emailCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtnEn(); //验证码倒数60秒
        });
      } else {
        let data = {
          phone: this.form.account,
          event: "changepwd",
        };
        phoneCode(data).then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.tackBtnEn(); //验证码倒数60秒
        });
      }
    },
    tackBtnEn() {
      //验证码倒数60秒
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.setCodeEn = "Verification code";
          this.disabled = false;
        } else {
          this.disabled = true;
          this.setCodeEn = "Try again in" + time + " secondS";
          time--;
        }
      }, 1000);
    },
    submitEn() {
      const reg = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      const reg1 = /^\d{6,13}$/;
      if (!this.form.account) {
        this.$message.error("Please enter your account number");
        return false;
      }
      if (!reg1.test(this.form.account) && !reg.test(this.form.account)) {
        this.$message.error("Please enter the correct account number");
        return false;
      }
      if (!this.form.code) {
        this.$message.error("Please enter the verification code");
        return false;
      }
      if (!this.form.password) {
        this.$message.error("Please enter password");
        return false;
      }
      if (!this.form.requirePas) {
        this.$message.error("Please enter your password again");
        return false;
      }
      if (this.form.password !== this.form.requirePas) {
        this.$message.error("Two passwords do not match");
        return false;
      }
      let data = {
        username: this.form.account,
        code: this.form.code,
        password: this.form.password,
      };
      upPassword(data).then(() => {
        this.$message({
          message: "Modified successfully！", //提示的信息
          type: "success", //类型是成功
          duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
          onClose: () => {
            localStorage.removeItem("userInfo");
            localStorage.removeItem("access_token");
            localStorage.removeItem("userId");
            localStorage.removeItem("typeInfo");
            localStorage.removeItem("userInfo");
            // localStorage.clear();
            this.$store.state.access_token = "";
            this.$store.state.user = {};
            //跳转页面或执行方法
            this.$router.push("/");
          },
        });
      });
      console.log(this.form);
    },
  },
};
</script>
<style scoped lang="scss">
.box {
  background: #fff;
  .ViewTitle {
    height: 67px;
    line-height: 67px;
    border-bottom: 1px solid #ebebeb;
    font-size: 20px;
    color: #333333;
    font-weight: 400;
    padding: 0 30px;
  }
  .contain {
    padding: 42px 30px;
    width: fit-content;
    .el-form-item {
      margin-bottom: 36px;
    }
    .submit {
      width: 320px;
      height: 56px;
      line-height: 56px;
      text-align: center;
      border-radius: 28px;
      margin: 0 auto;
      margin-top: 20px;
      background: var(--main-color);
      color: #ffffff;
      cursor: pointer;
    }
  }
}

::v-deep {
  .el-input__inner {
    height: 48px;
  }

  .el-form-item__label {
    color: #666;
    font-size: 16px;
    padding-right: 32px;
    line-height: 48px;
  }
  .codeBox {
    .el-form-item__content {
      display: flex;
      .el-input {
        flex: 1;
      }
      .setCode {
        width: 120px;
        height: 48px;
        // line-height: 50px;
        text-align: center;
        border-radius: 4px;
        background: var(--main-color);
        color: #fff;
        font-size: 14px;
        margin-left: 15px;
      }
    }
  }
}
.m-secure-page{
  background-color: #f8f8f8;
  .m-secure-box{
    margin: 0 20px;
    min-height: 74vh;
  }
  .m-submit-btn{
    margin: 20px;
    color: #fff;
    line-height: 40px;
    text-align: center;
    border-radius: 30px;
    background-color: var(--main-bg-color);
  }
}
</style>

